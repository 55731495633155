// styles & icons
import './Dashboard.css';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SchoolIcon from '@mui/icons-material/School';
import BookIcon from '@mui/icons-material/Book';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import React, { useState } from 'react'
import { v4 as uuid } from 'uuid';
import { useCollection } from "../../hooks/useCollection"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useFirestore } from "../../hooks/useFirestore"
import { timestamp } from '../../firebase/config';
import moment from 'moment';

import { PDFDocument } from '../../components/Notenauszug'
import { pdf } from "@react-pdf/renderer"

// mui components
  //Dialog
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogTitle from '@mui/material/DialogTitle';
  import Button from '@mui/material/Button';
  import TextField from '@mui/material/TextField';
  import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Snackbar, Alert } from '@mui/material';

export default function Dashboard() {

  const { user } = useAuthContext()
  const { updateDocument } = useFirestore("grades")
  const { documents, error } = useCollection(
    "grades", 
    null,
    user
  )

  const [userdata, setUserdata] = useState("")
  const [grade, setGrade] = useState("")
  const [subject, setSubject] = useState("")
  const [date, setDate] = useState("")
  const [type, setType] = useState("")
  const [subjectName, setSubjectName] = useState("")
  const [activeSubject, setActiveSubject] = useState("")
  const [pdfBlobURL, setPdfBlobURL] = useState("")

  const [deleteConfirmed, setDeleteConfirmed] = useState(false)


  const [isAddGradeModalOpen, setIsAddGradeModalOpen] = useState(false)
  const [isAddSubjectModalOpen, setIsAddSubjectModalOpen] = useState(false)
  const [isEditSubjectModalOpen, setIsEditSubjectModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isInfoSnackbarOpen, setIsInfoSnackbarOpen] = useState(false)


  if(documents && !userdata){
    setUserdata(documents)
  }


  const calcSubjectGrade = (data, subject) => {
    let gradesSum = 0;
    let count = 0;
    data.grades.map(g => {
      if (g.subjectId === subject.id) {
        gradesSum += g.grade * g.type;
        count += 1*g.type;
      }
      return null
    })
    const subjectGrade = gradesSum / count
    const subjectGradeRounded = subjectGrade.toFixed(2);
    return subjectGradeRounded;
  }

  const calcGeneralGrade = (data) => {
    let gradesSum = 0;
    let subjectsGrades = [];
    data.subjects.map(s => {
      if(parseFloat(s.averageGrade)){
        subjectsGrades.push(parseFloat(s.averageGrade))
      }
      return null
    })
    subjectsGrades.map(g => {
      gradesSum += g;
      return null
    })
    const generalGrade = gradesSum / subjectsGrades.length;
    const generalGradeRounded = generalGrade.toFixed(2);
    return generalGradeRounded;
  }

  const handleAddGrade = () => {
    if (grade && subject && date && type) {}
    const newGrade = {
      id: uuid(),
      subjectId: subject.id,
      grade,
      type,
      date: timestamp.fromDate(date)
    }
    let newUserdata = {...userdata}
    newUserdata.grades.push(newGrade)
    newUserdata.subjects.map(s => {
      if (s.id === subject.id) {
        s.averageGrade = calcSubjectGrade(newUserdata, s)
      }
      return null
    })
    updateDocument(user.uid, {grades: newUserdata.grades, subjects: newUserdata.subjects})
    setUserdata(newUserdata)
    setIsAddGradeModalOpen(false)
    setSubject("")
    setGrade("")
    setType("")
    setDate("")
  }

  const handleDeleteGrade = (grade) => {
    let newUserdata = {...userdata}
    newUserdata.grades = newUserdata.grades.filter(g => g.id !== grade.id)
    newUserdata.subjects.map(s => {
      if (s.id === grade.subjectId) {
        s.averageGrade = calcSubjectGrade(newUserdata, s)
      }
      return null
    })
    updateDocument(user.uid, {grades: newUserdata.grades, subjects: newUserdata.subjects})
    setUserdata(newUserdata)
  }

  const handleEditSubjectButton = (subject) => {
    setActiveSubject(subject)
    setIsEditSubjectModalOpen(true)
  }

  const handleAddSubject = () => {
    const newSubject = {
      id: uuid(),
      name: subjectName,
      averageGrade: 0
    }
    let newUserdata = {...userdata}
    newUserdata.subjects.push(newSubject)
    updateDocument(user.uid, {subjects: newUserdata.subjects})
    setUserdata(newUserdata)
    setIsAddSubjectModalOpen(false)
    setSubjectName("")
  }

  const handleEditSubject = () => {
    let newUserdata = {...userdata}
    newUserdata.subjects = newUserdata.subjects.map(s => {
      if(s.id === activeSubject.id){
        s.name = activeSubject.name
      }
      return s
    })
    updateDocument(user.uid, {subjects: newUserdata.subjects})
    setUserdata(newUserdata)
    setIsEditSubjectModalOpen(false)
    setSubjectName("")
  }

  const handleChangeSubjectName = (targetValue) => {
    let newActiveSubject = {...activeSubject}
    newActiveSubject.name = targetValue
    setActiveSubject(newActiveSubject)
  }

  const handleDeleteSubject = () => {
    let newUserdata = {...userdata}
    newUserdata.subjects = newUserdata.subjects.filter(s => s.id !== activeSubject.id)
    newUserdata.grades = newUserdata.grades.filter(g => g.subjectId !== activeSubject.id)
    updateDocument(user.uid, {subjects: newUserdata.subjects, grades: newUserdata.grades})
    setUserdata(newUserdata)
    setIsEditSubjectModalOpen(false)
    setIsConfirmModalOpen(false)
    setDeleteConfirmed(false)
  }

  const generatePDF = async (data) => {
    let generatedPDFDocumentBlob;
    try {
    	generatedPDFDocumentBlob = await pdf(
		    PDFDocument(data, calcGeneralGrade(data), user.displayName ? user.displayName : user.email)
    	).toBlob()
		setPdfBlobURL(URL.createObjectURL(generatedPDFDocumentBlob))
    } catch (error) {
    	console.error("Error when generating PDF:", error)
    }
    
  }
  

  return (
    <div>
        <Dialog
            open={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            fullWidth={true}
          >
            <DialogTitle>Möchtest du das Fach löschen</DialogTitle>
            <DialogContent style={{paddingTop: "10px"}}>
                <form>
                    <Stack spacing={1.5}>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox checked={deleteConfirmed} onChange={() => setDeleteConfirmed(!deleteConfirmed)}/>
                      } label={<p>Das Fach und <b><u>alle zugehörigen Noten</u></b> löschen</p>} />
                    </FormGroup>
                    <Button variant='contained' color='error' disabled={!deleteConfirmed} onClick={() => {handleDeleteSubject()}}>Löschen</Button>                      
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {setIsConfirmModalOpen(false); setDeleteConfirmed(false)}} color="primary">
                Abbrechen
              </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={isAddGradeModalOpen}
            onClose={() => {setIsAddGradeModalOpen(false)}}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>Neue Note</DialogTitle>
            <DialogContent style={{paddingTop: "10px"}}>
                <form className="addGradeForm">
                    <Stack spacing={1.5}>
                    <label>
                        <TextField
                            className="formInput"
                            label="Note"
                            onChange={(e) => setGrade(e.target.value)}
                            value={grade}
                            size="small"
                            select
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                        </TextField>
                    </label>
                    
                    <label>
                        <TextField
                            className="formInput"
                            label="Fach"
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                            size="small"
                            select
                        >
                          {userdata && userdata.subjects.map(s => (
                            <MenuItem key={s.id} value={s}>{s.name}</MenuItem>
                          ))}
                        </TextField>
                    </label>

                    <label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                label="Datum"
                                onChange={(date) => setDate(date)}
                                value={date}
                                size="small"
                                inputFormat="dd.MM.yyyy"
                                mask="__.__.____"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </label>

                    <label>
                        <TextField
                          className="formInput"
                          label="Gewichtung"
                          onChange={(e) => setType(e.target.value)}
                          value={type}
                          size="small"
                          select
                        >
                            <MenuItem value={1}>1x</MenuItem>
                            <MenuItem value={2}>2x</MenuItem>
                        </TextField>
                    </label>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setIsAddGradeModalOpen(false); setGrade(""); setSubject(""); setDate(""); setType("")}}>
                    Abbrechen
                </Button>
                <Button variant="contained" onClick={handleAddGrade}>Hinzufügen</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={isAddSubjectModalOpen}
            onClose={() => setIsAddSubjectModalOpen(false)}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>Neues Fach</DialogTitle>
            <DialogContent style={{paddingTop: "10px"}}>
                <form>
                    <Stack spacing={1.5}>
                    <label>
                        <TextField
                            className="formInput"
                            label="Fachname"
                            onChange={(e) => setSubjectName(e.target.value)}
                            value={subjectName}
                            size="small"
                        />
                    </label>
                    
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsAddSubjectModalOpen(false)}>
                    Abbrechen
                </Button>
                <Button variant="contained" onClick={handleAddSubject}>Erstellen</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={isEditSubjectModalOpen}
            onClose={() => setIsEditSubjectModalOpen(false)}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>Fach bearbeiten</DialogTitle>
            <DialogContent style={{paddingTop: "10px"}}>
                <form>
                    <Stack spacing={1.5}>
                    <label>
                        <TextField
                            className="formInput"
                            label="Fachname"
                            onChange={(e) => handleChangeSubjectName(e.target.value)}
                            value={activeSubject && activeSubject.name}
                            size="small"
                        />
                    </label>
                    
                    </Stack>
                </form>
                <br />
                <Button color="error" variant="contained" onClick={() => setIsConfirmModalOpen(true)}>Fach löschen</Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsEditSubjectModalOpen(false)}>
                    Abbrechen
                </Button>
                <Button variant="contained" onClick={() => handleEditSubject()}>
                  Speichern
                </Button>
            </DialogActions>
        </Dialog>

		<Snackbar open={isInfoSnackbarOpen} onClose={() => setIsInfoSnackbarOpen(false)} autoHideDuration={6000}>
			<Alert severity="info" onClose={() => setIsInfoSnackbarOpen(false)}>
				Um eine Note zu löschen doppelklick auf das Löschsymbol
			</Alert>
		</Snackbar>

      <h2>Gesamtschnitt: {userdata && calcGeneralGrade(userdata)}</h2>
      {!pdfBlobURL && <Button onClick={() => generatePDF(userdata)}>Notenauszug erstellen</Button>}
      {pdfBlobURL && <p><a href={pdfBlobURL} target="_blank" rel="noreferrer" style={{margin: "10px"}}>Notenauzug öffnen (PDF)</a></p>}

      <Grid container spacing={2}>
        {userdata && userdata.subjects !== [] && userdata.subjects.map(subject => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={subject.id}>
            <div className="subject">
              <h2>{subject.name}<IconButton onClick={() => handleEditSubjectButton(subject)}><EditIcon /></IconButton></h2>
              <TableContainer>
                <Table sx={{ maxWidth: 50 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Note</TableCell>
                      <TableCell>Datum</TableCell>
                      <TableCell>Gewichtung</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {userdata.grades.map(grade => (
                    grade.subjectId === subject.id && (
                      <TableRow key={grade.id}>
                        <TableCell><span style={{ fontSize: "1.3em", fontWeight: 700 }}>{grade.grade}</span></TableCell>
                        <TableCell>{moment(grade.date.toDate()).format("DD.MM.YYYY")}</TableCell>
                        <TableCell>{grade.type}x</TableCell>
                        <TableCell><IconButton onDoubleClick={() => handleDeleteGrade(grade)} onClick={() => setIsInfoSnackbarOpen(true)}><DeleteForeverIcon /></IconButton></TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
              <p>∅: {subject.averageGrade}</p>
            </div>
            </Grid>))}
      </Grid>

      <SpeedDial
        ariaLabel='SpeedDial add new grade ord subject'
        sx={{ position: 'fixed', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          tooltipTitle="Note"
		  tooltipOpen
          icon={<SchoolIcon />}
          onClick={() => setIsAddGradeModalOpen(true)}
        />
        <SpeedDialAction
          tooltipTitle="Fach"
		  tooltipOpen
          icon={<BookIcon />}
          onClick={() => setIsAddSubjectModalOpen(true)}
        />
      </SpeedDial>
      <p>{error && error}</p>
    </div>
  )
}
