import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import { AuthContextProvider } from "./context/AuthContext";

// styles
import './index.css';


const container = document.getElementById('root');
const root = createRoot(container); // 
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <center>
        <App />
      </center>
    </AuthContextProvider>
  </React.StrictMode>
);
