import firebase from "firebase/app"
import 'firebase/firestore'
import "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAWHZskpJFYCjxtZm34YSwLxk_RcmfmXtM",
  authDomain: "noti-4bcf1.firebaseapp.com",
  projectId: "noti-4bcf1",
  storageBucket: "noti-4bcf1.appspot.com",
  messagingSenderId: "944189792060",
  appId: "1:944189792060:web:fd9e7513948e8a15730839"
};

firebase.initializeApp(firebaseConfig)

// init service
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp }