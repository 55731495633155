import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
	page: {
		width: "100%",
		display: "flex",
		fontSize: 12,
		fontFamily: "Open Sans"
	},
	header: {
		width: "100%",
		display: "flex",
		textAlign: "center",
	},
	wrapper: {
		flexDirection: "column",
		justifyContent: "center",
		margin: "30px 30px 60px 30px",
	},
	tableWrapper: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	tr: {
		flexDirection: "row",
		justifyContent: "space-around",
		borderBottom: "1px solid #b4b4b4",
	},
	td: {
		width: "100%",
		margin: 0,
		padding: 5
	},
	subjectName: {
		fontSize: 14,
		fontWeight: 700,
		marginBottom: 10
	},
	average: {
		fontWeight: 700,
		marginTop: 10
	},
	pageNumbers: {
		position: 'absolute',
		bottom: 15,
		left: 0,
		right: 15,
		textAlign: 'right'
	  },
});

Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
	]
});


export const PDFDocument = (userdata, generalAverage, username) => (
	<Document>
		<Page size="A4" style={styles.page} wrap>
			<View style={styles.header}>
				<Text style={{fontSize: 15, fontWeight: 700}}>Notenauszug für {username}</Text>
				<Text style={{fontSize: 13, marginBottom: 10, marginTop: 10}}>Gesamtdurchschnitt: {generalAverage}</Text>
			</View>
			{userdata && userdata.subjects.map(subject => (
				<View style={styles.wrapper} key={subject.id} wrap={false}>
					<Text style={styles.subjectName}>{subject.name}</Text>

					<View style={styles.tableWrapper}>
						<View style={styles.tr}>
							<Text style={styles.td}>Note</Text>
							<Text style={styles.td}>Datum</Text>
							<Text style={styles.td}>Gewichtung</Text>
						</View>
						{userdata.grades.map(grade => (
							grade.subjectId === subject.id && (
								<View style={styles.tr} key={grade.id}>
									<Text style={[styles.td, {fontWeight: 700}]}>{grade.grade}</Text>
									<Text style={styles.td}>{moment(grade.date.toDate()).format("DD.MM.YYYY")}</Text>
									<Text style={styles.td}>{grade.type}x</Text>
								</View>
							)
						))}
						<Text style={styles.average}>Durchschnitt: {subject.averageGrade}</Text>
					</View>
				</View>
			))}
			<Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
				`Seite ${pageNumber} von ${totalPages}`
			)} fixed />
		</Page>
	</Document>
);
