import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"

// mui components
import { IconButton, Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

// styles
import styles from './Navbar.module.css'


export default function Navbar() {

    const { logout } = useLogout()
    const { user } = useAuthContext()
    const navigate = useNavigate()
  return (
    <div className={styles.navDiv}>
        <ul className={styles.navUl}>
            <li className={styles.navLi}><Link to="/">Home</Link></li>
            {!user &&
                <>
                    <li className={styles.navLi} style={{float: "right"}}><Button sx={{ m: 1 }} variant='contained' onClick={() => {navigate("/signup")}}>Signup</Button></li>
                    <li className={styles.navLi} style={{float: "right"}}><Button sx={{ m: 1 }} variant='contained' onClick={() => {navigate("/login")}}>Login</Button></li>
                </>
            }
            {user && <li style={{marginLeft: "16px", float: "right", color: "#fff"}}>Logged in as {user.displayName}<IconButton onClick={logout}><LogoutIcon sx={{ color: "#fff"}} /></IconButton></li>}
        </ul>
    </div>
  )
}
