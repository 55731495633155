import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React from "react"

// styles
import './App.css';

// pages
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';

// hooks
import { useAuthContext } from "./hooks/useAuthContext";

// components
import Navbar from './components/Navbar';


function App() {

  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={
            user ? <Dashboard /> : <Navigate to="/login" />
          } />
          <Route path="/login" element={
            user ? <Navigate to="/" /> : <Login />
          } />
          <Route path="/signup" element={
            user ? <Navigate to="/" /> : <Signup />
          } />
        </Routes>
      </BrowserRouter>
      )}
    </div>
  );
}

export default App;
